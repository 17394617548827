import React, { FC, useState, useCallback, useRef, useEffect } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Nav from '../components/nav'
import Critical from '../components/critical'
import useZzz from '../components/zzz'

import '../styles/index.css'

// critical image made at: https://www.textstudio.com/logo/baseball-text-effect-884
// svg critical at: https://suncatcherstudio.com/cursive-font/

const Slate = [
  "#f1f5f9", // 100
  "#e2e8f0", // 200
  "#cbd5e1", // 300
  "#94a3b8", // 400
  "#64748b", // 500
  "#475569", // 600
  "#334155", // 700
  "#1e293b", // 800
  "#0f172a", // 900
  "#020617", // 950
]
const Red = [
  "#fee2e2", // 100
  "#fecaca", // 200
  "#fca5a5", // 300
  "#f87171", // 400
  "#ef4444", // 500
  "#dc2626", // 600
  "#b91c1c", // 700
  "#991b1b", // 800
  "#7f1d1d", // 900
  "#450a0a", // 950
]

/* marpat
const colors = [
  "#2e2c35", // Dark Charcoal
  "#896e60", // shadow
  "#95837a", // Cinereous
  "#4e5b51", /// feldgrau
]
*/

function getRandomColor(pos: number) {
  return Math.random() + pos > 1.25
    ? Red[Math.floor(Math.random() * Red.length)]
    : Slate[Math.floor(Math.random() * Slate.length)]
}

function getCriticalColor(pos: number) {
  // return Math.random() + pos > 1.25
  return pos > 0.5
    ? Red[Math.floor(Red.length - 3 + Math.random() * 3)]
    : Slate[Math.floor(Slate.length - 3 + Math.random() * 3)]
}

// Draw MARPAT-like digital camo
function drawCamo(ctx: CanvasRenderingContext2D, width: number, height: number, runlen: number) {

  const timeSeg = 180 * 1000
  const pos = 1 - (Math.cos(((runlen % timeSeg) / timeSeg) * Math.PI * 2) * 0.5 + 0.5)

  // Clear canvas
  ctx.clearRect(0, 0, width, height)
  const tmp = Math.random()
  const bgcol = tmp + pos > 1.25
    ? Red[2]
    : Slate[2]
  ctx.fillStyle = bgcol
  ctx.fillRect(0, 0, width, height)

  const rectSize = 8 // Size of each "pixel" in the pattern
  const density = Math.min(1, 0.2 + pos) // Percentage of rectangles to draw

  for (let y = 0; y < height; y += rectSize) {
    for (let x = 0; x < width; x += rectSize) {
      if (Math.random() < density) {
        ctx.fillStyle = getRandomColor(pos)
        // Add small clusters by drawing several rectangles
        const clusterSize = Math.random() < 0.3 ? 3 : 2; // Single or double-sized clusters
        const clusterX = Math.random() < 0.5 ? rectSize : 0;
        const clusterY = Math.random() < 0.5 ? rectSize : 0;
        ctx.fillRect(x, y, rectSize * clusterSize, rectSize);
        ctx.fillRect(x + clusterX, y + clusterY, rectSize, rectSize * clusterSize);
      }
    }
  }
  return pos
}

const Page = ({
  Component,
  pageProps,
  showNav
}) => {
  return showNav
    ? <Component {...pageProps} />
    : null
}

const MyApp: FC<AppProps> = ({
  Component,
  pageProps
}) => {
  const [showNav, setShowNav] = useState(true)
  const [doNoise, setDoNoise] = useState(false)
  const [pos, setPos] = useState(0)
  const { setVol, resume, audioState, left, ctx } = useZzz()
  const canvRef = useRef<HTMLCanvasElement>()

  useEffect(() => {
    const canvas = canvRef.current
    const context = canvas.getContext('2d', { alpha: false });

    function onResize() {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
      if (!doNoise)
        window.requestAnimationFrame(onAnimate)
    }

    let done = !doNoise
    let start: number
    let max: number
    let elapsed: number
    let then: number
    const fps = 15
    const fpsInterval = 1000 / fps

    function onAnimate(timestamp: number) {

      if (start === undefined) {
        start = timestamp
        then = start
        max = 0
      }
      const len = 180 * 1000
      const runlen = timestamp - start
      elapsed = timestamp - then
      if (max === 0 || elapsed > fpsInterval) {
        then = timestamp - (elapsed % fpsInterval);
        // runlen comes in as millisecons
        const mul = 1 - (Math.cos(((runlen % len) / len) * Math.PI * 2) * 0.5 + 0.5)
        max = Math.max(mul, max)

        const pos = drawCamo(context, canvas.width, canvas.height, timestamp - start)
        setPos(doNoise ? pos : 0)
      }
      if (!done)
        window.requestAnimationFrame(onAnimate)
    }
    window.addEventListener('resize', onResize)
    onResize()
    window.requestAnimationFrame(onAnimate)
    return () => {
      window.removeEventListener('resize', onResize)
      done = true
    }
  }, [doNoise])

  const toggle = useCallback(() => resume()
    .then((astate) => setShowNav(sn => {
      const play = sn && astate === 'running'
      if (play) {
        setDoNoise(true)
        if (left && ctx) {
          left.frequency.setValueAtTime(220, ctx.currentTime)
          left.frequency.linearRampToValueAtTime(210, ctx.currentTime + 3)
          left.frequency.setValueAtTime(210, ctx.currentTime + 3.0001)
          left.frequency.linearRampToValueAtTime(205, ctx.currentTime + 13)
          left.frequency.setValueAtTime(205, ctx.currentTime + 13.0001)
          left.frequency.linearRampToValueAtTime(225, ctx.currentTime + 60)
          left.frequency.setValueAtTime(225, ctx.currentTime + 60.0001)
          left.frequency.linearRampToValueAtTime(210, ctx.currentTime + 180)
          left.frequency.setValueAtTime(225, ctx.currentTime + 180.0001)
          left.frequency.linearRampToValueAtTime(220, ctx.currentTime + 360)
        }
        setVol(0.5)
        if (!document.fullscreenElement) {
          document.body.requestFullscreen().catch(console.log)
        }
      } else {
        setDoNoise(false)
        setPos(0)
        setVol(0.0)
        if (document.fullscreenElement) {
          document.exitFullscreen()
        }
      }

      return !sn
    }))
    .catch(console.error)
    , [resume, setVol, left, ctx])


  return (
    <>
      <Head>
        <link rel="icon" href="https://assets.august.black/img/camo64.png" type="image/png" />
        <title>august black</title>
        <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
        <meta httpEquiv="content-language" content="EN" />
        <meta name="Author" content="August Black" />
        <meta name="Publisher" content="August Black" />
        <meta name="Keywords" content="August Black, black august, Augusto Nero, Augusto Negro, Gustl Schwarz,
          la radia, mezcal, userradio, free software, open source, gnu, culture, art, kunst, whatever, augmented space,
          augmentus, augment, radio, radio art, software, pure data, datadada," />
        <meta name="description" content="the black august of time and space" />
        <meta name="Abstract" content="This is the online content of August Black, great lover of art
          and poetry." />
        <meta name="Copyright" content="All contents Copyright © August Black 1999-2021." />
        <meta name="Distribution" content="global" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="August Black" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://august.black" />
        <meta property="og:image" content="https://assets.august.black/img/AugustBlack_Siggraph2024-1080.webp" />
        <meta property="og:audio" content="https://assets.august.black/media/datadada/artsbday_black_hopkins.mp3" />
        <meta property="og:video" content="https://assets.august.black/media/pulse/pulse_nov_2024.mp4" />
      </Head>
      <canvas ref={canvRef} className="fixed left-0 top-0 -z-10 " />
      {pos > 0.0001
        ? (
          <div className="fixed top-0 left-0 w-screen h-screen p-4 -rotate-12 scale-90" style={{ color: getCriticalColor(pos) }}>
            <Critical pos={pos * 0.7} />
          </div>
        )
        : null
      }
      <div className="p0 lg:p-10 xl:p-12">
        <Nav toggle={toggle} show={showNav} canPlayAudio={audioState === 'running'} />
        <Page Component={Component} pageProps={pageProps} showNav={showNav} />
      </div>

    </>
  )
}

export default MyApp
